import React from 'react';
import './Services.scss';

class Services extends React.Component {
    render() {
        return <>
            {
                this.props.serviceData.map((item, index) => <div className={this.props.hoverStyle ? 'services-item hover' : 'services-item'} key={index}>
                    <div className="img-wrap">
                        <img src={item.imgSrc} alt="icon" />
                    </div>
                    <h4>{item.heading}</h4>
                    <p>{item.description}</p>
                </div>
                )
            }

        </>
    }
}

export default Services;