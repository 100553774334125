import React from 'react';
// import { Link } from 'react-router-dom';
import './Banner.scss';
import Modal from '../Modal/ModalComponent';

class Banner extends React.Component {
    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.state = {
            openModal: false,
        };
    };

    toggleModal=()=> {
        this.setState({ openModal: !this.state.openModal });
    }
    render() {
        return <div className="banner" id="banner">
            <div id="home"></div>
            {/* <img className="bg-one" src="banner-bg.svg" alt="banner-bg" />
            <img className="bg-two" src="banner-bg-2.svg" alt="banner-bg" />
            <img className="bg-three" src="banner-bg-circle.svg" alt="banner-bg" />
            <img className="bg-mask-1" src="banner-mask-1@3x.png" alt="banner-bg" /> */}
            {/* <img className="banner-play-btn" src="play.svg" alt="banner-play" /> */}
            <div className="banner-content">
                <h1>
                    We Build Technology <br />Work For You
                </h1>
                <p className="hide-tab">IT specialists that provides you<br/> Trusted Technologies and Custom Solutions</p>
                <button className="contact-btn" onClick={() => this.toggleModal()}>Contact Us</button>
            </div>
            {this.state.openModal ? <Modal onClose={this.toggleModal} /> : ''}
        </div>
    }
}

export default Banner;