import React from 'react';
import './Footer.scss';

const services = ["Website design & development",
    "Web app development",
    "Android app development",
    "iPhone/iPad app development",
    "Artificial Inteligence & ML",
    "E-commerce development",
    "Service consulting",
    "Business branding",
    "System testing",
    "DevOps"
];

class Footer extends React.Component {
    render() {
        return <div className="footer" id="contact">
            <div className="footer-col">
                <h4>Who we are</h4>
                <p>Whatever your software application needs, we are a fully committed, energetic team of kick-ass analysts, designers, developers, and project managers with one goal in mind -- helping our clients and their products shine.</p>
            </div>
            <div className="footer-col">
                <h4>Our Services</h4>
                {
                    services.map((item, index) =>
                        <p key={index}>{item}</p>
                    )
                }   
            </div>
            <div className="footer-col hide-mobile">
                <h4> Our Products</h4>
                <ul>
                    <li><p>Patient Management System</p></li>
                    <li><p>Ecommerce Management System</p></li>
                </ul> 
            </div>
            <div className="footer-col">
                <h4>Contact</h4>
                <p>
                    Mobile 10X Building, 28/1650D, <br/>
                    Govt. CyberPark, Kozhikode,<br/>
                    Kerala, India. Pin: 673 016
                </p>
                <p>
                    <a href="mailto:info@ignitif.com" target="_top">info@ignitif.com</a>
                </p>
                {/* <div className="social-icon">
                    <a href="https://www.facebook.com/ignitif/" target="_blank" rel="noopener noreferrer">
                        <img src="fb.svg" alt="social-icon" />
                    </a>
                    <a href="https://www.linkedin.com/in/ignitif-company-5085261a5/"  target="_blank" rel="noopener noreferrer">
                        <img src="linkedin.svg" alt="social-icon" />
                    </a>
                    <a href="https://twitter.com/ignitif"  target="_blank" rel="noopener noreferrer">
                        <img src="twitter.svg" alt="social-icon" />
                    </a>
                    <a href="https://www.instagram.com/ignitif/" >
                        <img src="youtube.svg" alt="social-icon" />
                    </a>
                </div> */}
            </div>
        </div>
    }
}

export default Footer;