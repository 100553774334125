import React from 'react';
import './CaseStudy.scss';
import ProjectItem from './ProjectItem';

class CaseStudy extends React.Component {
    render() {
        return <div className="case-study-container" id="our-portfolio">
            <h2>Portfolio</h2>
            <div className="project-wrap">
            <ProjectItem />
            </div>
        </div>
    }
}

export default CaseStudy;