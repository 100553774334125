import React from 'react';
import Slider from 'react-slick';

import './CaseStudy.scss';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import * as constants from '../../variables/Constants';
import Modal from '../Modal/ModalComponent';

function SamplePrevArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{ ...style, display: 'block' }}
			onClick={onClick}
		>
			<img src="arrow.svg" alt="arrow" />
		</div>
	);
}

class ProjectItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			content: '',
		};
	}

	showModal = (item) => {
		this.setState({
			showModal: !this.state.showModal,
			content: item,
		});
	};

	render() {
		const settings = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 3,
			slidesToScroll: 1,
			adaptiveHeight: true,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
						infinite: true,
						dots: true,
					},
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
			prevArrow: <SamplePrevArrow className={'arrow'} />,
			nextArrow: <SamplePrevArrow className='right-arrow'/>,
		};
		return (
			<>
				<Slider {...settings}>
					{constants.projectItems.map((item) => {
						return (
							<div key={item.name} className="carousel-cards">
								<img src={item.imgSrc} alt={item.name} />
								<div className="content-overlay"></div>
								<div className="content-details-overlay">
									<p>{item.name}</p>
									<div className="short-desc">
										{item.desc}
									</div>
									{/* <button
										className="btn read-more-btn"
										onClick={() => this.showModal(item)}
									>
										Read More
									</button> */}
								</div>
							</div>
						);
					})}
				</Slider>
				{this.state.showModal ? (
					<Modal
						showContactForm={false}
						onClose={this.showModal}
						projectDetails={this.state.content}
					/>
				) : null}
			</>
		);
	}
}

export default ProjectItem;
