import React from 'react';
import './Services.scss';
import ServiceItem from './ServiceItemComponent';
const serviceItems = [
	{
		imgSrc: 'service-1.svg',
		heading: 'Service Consulting',
		description:
			'If you already have a team and are looking for accelerating the development and delivery. We will help you study and improve on existing processes, analyze and suggest better technological approaches based on the requirements and the complexity of the current codebase.',
	},
	{
		imgSrc: 'service-2.svg',
		heading: 'DevOps',
		description:
			'Businesses today face the constant threat of technology disruption which can severely affect their competitiveness. DevOps helps organizations to deliver applications, products and services at a much faster velocity than traditional methods. We provide specialized DevOps support services to ensure faster delivery with reduced risk.',
	},
	{
		imgSrc: 'service-3.svg',
		heading: 'Application Development',
		description:
			'Applications offer a helping hand in businesses to automate processes and increase efficiency. We offer a wide variety of applications to cater your needs whether it’s Mobile Applications for Android, iPhone, Tablets or scalable Web Applications or just static company Websites Development , we have all covered to provide you the best business solutions.',
	},
	{
		imgSrc: 'service-4.svg',
		heading: 'E-commerce Development',
		description:
			'We offer better end-to-end functionality in your eCommerce solutions. Cloud-backed, scalable on-line stores, Social Media integration, upselling and cross selling and efficient search functionality will ensure high conversions and user delight besides shortening the sales cycle.Our solutions have simplified user experience and navigation with quick response time, taking your ecommerce business to new heights.',
	},
];

class Services extends React.Component {
	render() {
		return (
			<div className="services-container">
				<div id="services"></div>
				<div className="services-content">
					<h2>Awesome Customer Services</h2>
					<p>
						At <span className="ignitif-name">ignitif</span> we
						serve you with end-to-end software consulting and
						development solutions. We help you plan, conceive,
						incorporate, build, augment and take care of your
						software with the help of our industry experts from
						different knowledge domains - offering you absolute
						benefits from our expert consulting.
					</p>
					<img
						className="customer-img"
						src="/customer service.png"
						alt="customer services"
					/>
				</div>
				<div className="services-items">
					<div className="service-col">
						<ServiceItem
							serviceData={serviceItems.slice(0, 2)}
							hoverStyle={true}
						/>
					</div>
					<div className="service-col">
						<ServiceItem
							serviceData={serviceItems.slice(2, 4)}
							hoverStyle={true}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default Services;
