import React from 'react';
import './StackBar.scss';
// Including in the bundle as it is SVG
import PythonLogo from './Images/python.png';
import PyTorchLogo from './Images/py-torch.jpeg';
import PhpLogo from './Images/php.png';
import HtmlLogo from './Images/html5.png';
import AngularLogo from './Images/angular.png';
import WPLogo from './Images/wordpress.png';
import NodeLogo from './Images/node.png';
import ReactLogo from './Images/react.png';
import VueJSLogo from './Images/vuejs.png';
import JavaLogo from './Images/java.png';
import AndroidLogo from './Images/android.png';
import AppleLogo from './Images/ios.png';
import FlutterLogo from './Images/flutter-logo.png';
import TFLogo from './Images/tensor.jpeg';
import RubyLogo from './Images/ruby.png';
import AwsLogo from './Images/aws.png';

const stackDetails = [
	{ stackName: 'Angular', imgSrc: AngularLogo },
	{ stackName: 'React', imgSrc: ReactLogo },
	{ stackName: 'Vue.JS', imgSrc: VueJSLogo },
	{ stackName: 'Java', imgSrc: JavaLogo },
	{ stackName: 'Node.JS', imgSrc: NodeLogo },
	{ stackName: 'Ruby', imgSrc: RubyLogo },
	{ stackName: 'Python', imgSrc: PythonLogo },
	{ stackName: 'PHP', imgSrc: PhpLogo },
	{ stackName: 'HTML', imgSrc: HtmlLogo },
	{ stackName: 'WordPress', imgSrc: WPLogo },
	{ stackName: 'Android', imgSrc: AndroidLogo },
	{ stackName: 'Apple', imgSrc: AppleLogo },
	{ stackName: 'Flutter', imgSrc: FlutterLogo },
	{ stackName: 'AWS', imgSrc: AwsLogo },
	{ stackName: 'PyTorch', imgSrc: PyTorchLogo },
	{ stackName: 'TensorFlow', imgSrc: TFLogo },
];

export default class StackBarComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			stackDetails: [],
		};
	}

	componentDidMount() {
		this.setState({
			stackDetails: stackDetails,
		});
	}

	render() {
		return (
			<div className="stack-bar-container">
				<span className="tech-expertise">
					Our Techinical Expertise:
				</span>
				<section className="stack-container">
					{this.state.stackDetails.map((stack) => {
						return (
							<img
								src={stack.imgSrc}
								alt={stack.stackName}
								key={stack.stackName}
								title={stack.stackName}
							/>
						);
					})}
				</section>
			</div>
		);
	}
}
