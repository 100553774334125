import React from 'react';
import './About.scss';
import ServiceItems from '../Services/ServiceItemComponent';
import Testimonials from '../Testimonials/TestimonialsComponent';

const serviceData = [
	{
		imgSrc: 'service-1.svg',
		heading: 'Patient Management System',
		description:
			'As we focus on the healthcare system we  truly understand that for a healthcare care institution priority is utmost for getting the issues addressed from patients. Our sophisticated patient management system helps you to focus on this and keep aside the dilemmas of paperwork, scheduling appointments, billing and keeping records. They make your job as a care provider smoother and more manageable and lets you stay focussed on the patient.',
	},
	{
		imgSrc: 'service-2.svg',
		heading: 'Ecommerce Management System',
		description:
			'Ecommerce is disruptive. It has changed the way millions of people shop, but the management of many ecommerce businesses has not evolved. Technology plays an important role in getting on top of ecommerce business. Our high end ecommerce management system gives you an edge on the market. From catchy product display to seamless checkout experience we have it all covered.',
	},
];

const values = [
	'Be client centric',
	'Strictly adhere to ethical and moral values',
	'Be proactive to business needs',
	'Nurture a spirit of dynamism across the organization',
];
class About extends React.Component {
	render() {
		return (
			<>
				<div className="about-container">
					<div id="about"></div>
					<div className="about-text-container">
						<h2>We have creative team build your business</h2>
						<p>
							<span className="ignitif-name">ignitif</span> is a
							complete IT solution provider company with a unique software
							development process. We make software that is high
							on quality and speed along with being economical as
							well. We develop software with the right people in
							the right places. Our all-encompassing health
							management ecosystem has one simple mission: better
							care at a lower cost. Our suite of solutions is
							directed at simplifying patient-physician
							interactions within a cost-effective model. All{' '}
							<span className="ignitif-name">ignitif</span>{' '}
							systems are interoperable and built to maximise
							efficiency, storing and sorting large volumes of
							data on secure HIPAA compliant cloud servers with
							the in-built intelligent health exchange system.
						</p>
						<br />
						<p>
							We embrace the diversity of our team as we continue
							to grow beneficial partnerships with our clients,
							employees and communities
						</p>
						<br />
						<div className="about-sub-section">
							<h3>Vision</h3>
							<p>
								<span className="ignitif-name">ignitif'</span>s
								vision is to become a quality based engineering
								organization of global reputation. We aim to be
								the first choice for dynamic software solutions
								integrating engineering research & technology
							</p>
						</div>
						<div className="product-wrap">
							<div className="services-item">
								<h4>Values</h4>
								<p>
									<span className="ignitif-name">
										ignitif
									</span>{' '}
									has adopted and implemented certain values
									across its organization that is followed
									steadfastly:
								</p>
								<ul>
									{values.map((item, index) => (
										<li key={index}>{item}</li>
									))}
								</ul>
							</div>
							<div className="services-item">
								<h4>Mission</h4>
								<p>
									Our Mission is to provide innovative,
									economical and convenient software products
									services using a Collaborative Business
									Model called BQM (Business Quality Model).
									BQM integrates business expertise from
									various sources and provides composite Top
									Business Quality Solutions and Services to
									customers. Reliability, Trust and Friendship
									are the foundation for our success.
								</p>
							</div>
						</div>
					</div>
					<div className="about-img-container">
						{/* <img className="img-rt" src="about-11@3x.png" alt="about-img" /> */}
						{/* <div className="about-bg">
            <div className="about-bg-img">
            </div>
          </div> */}
						<img
							className="about-right-img"
							src="/about-image@3x.png"
							alt="products"
						/>

						<div className="quote">
							<img src="/quoteleft.svg" alt="quote" />
							<div>
								Our relationships
								<br /> often extend beyond
								<br /> the first engagement
							</div>
							<img src="/quoteright.svg" alt="quote" />
						</div>
					</div>
				</div>
				<Testimonials />
				<div className="about-container about-two">
					<div className="about-img-container">
						{/* <img src="Mask Group 3@3x.png" alt="about-img" /> */}
						{/* <div className="about-bg two">
            <div className="about-bg-img two">
            </div>
          </div> */}
						<img
							className="customer-img"
							src="/products image.png"
							alt="products"
						/>
					</div>
					<div className="about-text-container">
						<div id="case-study"></div>
						<h2>Creating ideas and building powerful products</h2>
						<p>
							We at <span className="ignitif-name">ignitif</span>{' '}
							follow agile development patterns and employ scrum
							methodologies for project execution. Each product
							development goes through a UI/UX review and approval
							phase before starting with development. We will
							provide you with timely updates during different
							development phases for feedback. The final product
							goes through a vigorous testing phase to ensure
							quality and correctness.
						</p>
						<div className="product-wrap">
							<ServiceItems serviceData={serviceData} />
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default About;
