import React from 'react';
import './App.scss';
import { Route, Switch } from 'react-router-dom';

// import Blog from './Components/Blog/BlogComponent';
import NotFound from './Components/NotFound/NotFoundComponent';
import Home from './Components/Home/HomeComponent';
function App() {
  return (
    <div className="App">

      <Switch>
        <Route exact path="/" component={Home} />
        {/* <Route path="/blog" component={Blog} /> */}
        {/* <Route path="/blog/:id" component={Blog} /> */}
        <Route component={NotFound} />
      </Switch>
    </div>
  );
}

export default App;
