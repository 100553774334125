export const projectItems = [
	{
		name: 'Order My Dine',
		imgSrc: 'projects/omd.jpeg',
		// Add your short desc here...
		desc:
			'',
		servicesOffered: ['design', 'development', 'testing'],
	},
	{
		name: 'Waiter Order App',
		imgSrc: 'projects/WaiterOrderApp.png',
		desc:
			'',
		servicesOffered: ['design', 'development', 'testing'],
	},
	{
		name: 'Chicago cargo LLC, Fujairah, UAE',
		imgSrc: 'projects/chicago.jpeg',
		// Add your short desc here...
		desc:
			'',
		servicesOffered: ['design', 'development', 'testing'],
	},
	{
		name: 'Maintenance Tracking System',
		imgSrc: 'projects/pms.png',
		desc:
			'',
		servicesOffered: ['design', 'development', 'testing'],
	},
	{
		name: 'Songnitif',
		imgSrc: 'projects/songnitif.jpeg',
		// Add your short desc here...
		desc:
			'',
		servicesOffered: ['design', 'development', 'testing'],
	},
	{
		name: 'VMiles',
		imgSrc: 'projects/VMiles.png',
		// Add your short desc here...
		desc:
			'',
		servicesOffered: ['design', 'development', 'testing'],
	},
	{
		name: 'Hospital management application',
		imgSrc: 'projects/patient.jpg',
		desc:
			'',
		servicesOffered: ['design', 'development', 'testing'],
	},
	{
		name: 'e-Commerce Application',
		imgSrc: 'projects/ecommerce.jpg',
		desc:
			'',
		servicesOffered: ['design', 'development', 'testing'],
	},
];
