import React from "react";
import * as emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";
import Form from "muicss/lib/react/form";
import Input from "muicss/lib/react/input";
import Textarea from "muicss/lib/react/textarea";
import Button from "muicss/lib/react/button";
import Row from "muicss/lib/react/row";
import Col from "muicss/lib/react/col";
import Option from "muicss/lib/react/option";
import Select from "muicss/lib/react/select";
import "./ContactForm.scss";

const services = [
  "Website design & development",
  "Web app development",
  "Android app development",
  "iPhone/iPad app development",
  "ECommerce development",
  "Service consulting",
  "Business branding",
  "System testing",
  "DevOps",
];

const recaptchaRef = React.createRef();

class Contactform extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      phone: "",
      email: "",
      enquiry: "",
      message: "",
      captcha: "",
      showForm: true,
    };
  }
  handleSubmit(e) {
    e.preventDefault();
    const { name, phone, email, enquiry, message } = this.state;
    let templateParams = {
      from_name: name,
      to_name: "ignitif",
      subject: "Web enquiry",
      message_html: `Name: ${name} <br/> <br/>
            Phone: ${phone} <br/> <br/>
            Email: ${email} <br/> <br/>
            Enquiry about: ${enquiry} <br/> <br/>
            Description: ${message}`,
    };
    emailjs.send(
      "contact_us",
      "ignitif_email_template",
      templateParams,
      "user_W42mNRBXeei8KFIM6XYXc"
    );
    this.resetForm();
    setTimeout(() => this.props.onClose(), 1500);
  }

  resetForm() {
    this.setState({
      name: "",
      phone: "",
      email: "",
      enquiry: "",
      message: "",
      captcha: false,
      showForm: false,
    });
  }

  handleChange = (param, e) => {
    this.setState({ [param]: e.target.value });
  };

  updateCaptcha = () => {
    const recaptchaValue = recaptchaRef.current.getValue();
    if (recaptchaValue) {
      this.setState({ captcha: true });
    }
  };

  render() {
    return (
      <>
        {this.state.showForm ? (
          <>
            <div className="modal-title">Contact Us</div>
            <Form onSubmit={this.handleSubmit.bind(this)}>
              <Row>
                <Col xs="6">
                  <Input
                    label="Name"
                    type="text"
                    floatingLabel={true}
                    required={true}
                    onChange={this.handleChange.bind(this, "name")}
                  />
                </Col>
                <Col xs="6">
                  <Input
                    label="Contact No"
                    type="tel"
                    floatingLabel={true}
                    required={true}
                    onChange={this.handleChange.bind(this, "phone")}
                  />
                </Col>
              </Row>
              <Input
                label="Email"
                type="email"
                floatingLabel={true}
                required={true}
                onChange={this.handleChange.bind(this, "email")}
              />

              <Select
                name="input"
                label="Enquiry about"
                defaultValue="Enquiry about"
                onChange={this.handleChange.bind(this, "enquiry")}
              >
                {services.map((item, index) => (
                  <Option value={item} label={item} key={index} />
                ))}
              </Select>
              <Textarea
                label="Description"
                floatingLabel={true}
                required={true}
                rows="2"
                onChange={this.handleChange.bind(this, "message")}
              />

              {/* <ReCAPTCHA
                sitekey="6LfyHuUUAAAAALNHDXYndKHhHM27UQeJK7Y9b5VR"
                onChange={this.handleChange.bind(this, "captcha")}
              /> */}
              <ReCAPTCHA
                sitekey="6LfyHuUUAAAAALNHDXYndKHhHM27UQeJK7Y9b5VR"
                onChange={this.updateCaptcha}
                ref={recaptchaRef}
              />

              {/* <input className="submit-btn" type="submit" value="Submit" />
            <div className="submit-btn" onClick={this.props.onClose}>
              Cancel
            </div> */}
              <Button
                variant="raised"
                color="primary"
                disabled={this.state.captcha ? false : true}
              >
                Submit
              </Button>
              <Button variant="raised" onClick={this.props.onClose}>
                Cancel
              </Button>
            </Form>
          </>
        ) : (
          <div className="alert-info">
            Thank You.! <br/>
            Your enquiry submitted successfully.
          </div>
        )}
      </>
    );
  }
}

export default Contactform;
