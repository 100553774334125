import React from 'react';
import './Modal.scss';
import ContactForm from '../ContactForm/ContactComponent';
import ProjectDetails from '../CaseStudy/projectDetails.';

class Modal extends React.Component {
	render() {
		return (
			<div className="modal-container" onClick={this.props.onClose}>
				<div
					className="modal-content"
					onClick={(e) => e.stopPropagation()}
				>
					<img
						className="close-btn"
						src="/close.svg"
						alt="close"
						onClick={this.props.onClose}
					/>
					{this.props.showContactForm ? (
						<ContactForm onClose={this.props.onClose} />
					) : (
						<ProjectDetails content = {this.props.projectDetails} />
					)}
				</div>
			</div>
		);
	}
}

Modal.defaultProps = {
	showContactForm: true,
};

export default Modal;
