import React from 'react';
import './Testimonials.scss';
const testimonialData = [
	{
		icon: 'quote.svg',
		userImage: 'testimonial/levin.jpg',
		userName: 'Lavin Vinay',
		userCompany: 'General Manager - Chicago ShipChandlers',
		userCountry:'United Arab Emirates',
		text: 'We love working with ignitif because they listen and help us create an optimum strategy to get where we want to go - fast. It definitely helps that they are super smart and have a genuine depth of experience to draw upon.',
	},
	{
		icon: 'quote.svg',
		userImage: 'testimonial/soji.jpg',
		userName: 'Soji Joseph',
		userCompany: 'Director - Waiktech Limited',
		userCountry:'New Zealand',
		text: 'They have always listened to us properly and came up with quick and easy solutions. We never had to stop and wait for their solutions. Their service in networking and software development is incomparable. We will always go back and highly recommended.',
	},
	{
		icon: 'quote.svg',
		userImage: 'testimonial/hanef1.jpg',
		userName: 'Haneef Ahmed',
		userCompany: 'Chief evangelist - Technestia',
		userCountry:'United Arab Emirates',
		text: 'ignitif does what comes naturally to them that is build confidence with clients, and provide exceptional business solutions. We appreciate your dedication and support.',
	}
];

class Testimonials extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			testmonialIndex: 0,
		};
	}

	componentDidMount() {
		this.startTimer = setInterval(()=> {
			this.nextTestimonial()
		},5000)
	}

	componentWillUnmount() {
		clearInterval(this.startTimer)
	}

	previousTestimonial = () => {
		if (this.state.testmonialIndex === 0) {
			this.setState({ testmonialIndex: testimonialData.length - 1 });
		} else {
			this.setState({ testmonialIndex: this.state.testmonialIndex - 1 });
		}
	};

	nextTestimonial = () => {
		if (this.state.testmonialIndex === testimonialData.length - 1) {
			this.setState({ testmonialIndex: 0 });
		} else {
			this.setState({ testmonialIndex: this.state.testmonialIndex + 1 });
		}
	};
	render() {
		return (
			<div className="testimonial-container">
				<h2>Testimonials</h2>

				<div className="testimonial-box">
					<div className="test-icon">
						<img
							src={
								testimonialData[this.state.testmonialIndex].icon
							}
							alt="icon"
							className="icon-quotes"
						/>
						<img
							src={
								testimonialData[this.state.testmonialIndex]
									.userImage
							}
							alt="user"
							className="user-image"
						/>
					</div>
					<section className="testimonials-content">
						<p className="test-text">
							{testimonialData[this.state.testmonialIndex].text}
						</p>
						<p className="user-name">
							{
								testimonialData[this.state.testmonialIndex]
									.userName
							}
						</p>
						<p className="user-company">
							{
								testimonialData[this.state.testmonialIndex]
									.userCompany
							}
						</p>
						<p className="user-country">
							{
								testimonialData[this.state.testmonialIndex]
									.userCountry
							}
						</p>
					</section>
				</div>
				<div className="test-arrow-wrap">
					<div
						className="arrow left-arrow"
						onClick={this.previousTestimonial}
					>
						<img src="arrow.svg" alt="arrow" />
					</div>
					<div
						className="arrow right-arrow"
						onClick={this.nextTestimonial}
					>
						<img src="arrow.svg" alt="arrow" />
					</div>
				</div>
			</div>
		);
	}
}

export default Testimonials;
