import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.scss';
import App from './App';
import Header from './Components/Header/HeaderComponent';
import Footer from './Components/Footer/FooterComponent';
import * as serviceWorker from './serviceWorker';

const routing = (
    <Router>
        <div>
            <Header />
            <App />
            <Footer />
        </div>
    </Router>
)

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
