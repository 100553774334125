import React from 'react';

export default class ProjectDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			initialState: null,
		};
	}

	render() {
		const { name, servicesOffered } = this.props.content;
		return (
			<section className="custom-component">
				<img
					className="header-image"
					alt={this.props.content.name}
					src={this.props.content.imgSrc}
				/>
				<p className="project-title text-modal">{name}</p>
				<hr className="modal-underline" />
				<div className="services-list-container">
					<p className="text-modal services-text">Services Offered: </p>
					<ul className="services-list">
						{servicesOffered.map((service) => {
							return (
								<li className="service" key={service}>
									{service}
								</li>
							);
						})}
					</ul>
				</div>
				<p className="project-desc text-modal">
					{this.props.content.desc}
				</p>
			</section>
		);
	}
}

ProjectDetails.defaultProps = {
	content: '',
};
