import React from 'react';
import Banner from '../Banner/BannerComponent';
import Services from '../Services/ServicesComponent';
import About from '../About/AboutComponent';
import CaseStudy from '../CaseStudy/CaseStudyComponent';

import './Home.scss';
import StackBarComponent from '../StackBar/StackBarComponent';
class Home extends React.Component {
	render() {
		return (
			<div className="home">
				<Banner />
				<Services />
				<StackBarComponent />
				<About />
				<CaseStudy />
				{/* <ImageCarousal /> */}
			</div>
		);
	}
}

export default Home;
